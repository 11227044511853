/* svg {
  display: block;
  width: 100%;
  height: 300px;
  background: #f7f7f7;
  overflow: visible;
}

svg rect.selection {
  stroke: none;
} */

/* #root {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
    text-align: center;
    box-sizing: border-box;
  } */

/* button {
  width: 100px;
  border: 1px solid black;
  margin: 0 auto 5px;
} */

/* video {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  width: 100%;
}

.fields {
  margin-bottom: 1rem;
}

.field {
  display: inline-block;
  margin: 0 0.5em;
}

.field input {
  margin-right: 0.5em;
} */

.unit {
  border: 2px;
  border-color: black;
}
